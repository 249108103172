<script>
import Layout from "@/router/layouts/main";
import axios from "axios";
import Swal from "sweetalert2";
export default {
    components: {
        Layout,
    },
    data() {
        return {
            'page' : this.$route.query.page,
            'data' : this.$route.query,
            'listRender' : []
        }
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler(newVal) {
                let path = this.$route.path;
                if(path == "/render"){
                    let page = newVal.page;
                    this.getListRenderComponent(page);
                    this.data = newVal;
                }
            }
        }
    },
    beforeMount(){
        let self = this;
        self.getListRenderComponent(this.page)
    },
    methods: {
        getListRenderComponent(page){
            let self = this;
            var config_axios = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/page/list-render",
                params: {
                    page: page,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config_axios)
            .then(function (response) {
                var response_data = response.data;
                if (response_data.meta.code == 200) {
                    self.listRender = response_data.data;
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data.meta.message,
                    });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error.response.data.meta.message,
                    });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Terjadi kesalahan pada sistem.",
                    });
                }
            })
        },
    }
}
</script>

<template>
    <Layout>
        <div v-for="(value, row) in listRender" :key="row" class="row">
            <div v-for="(detail, key) in value" :key="key" :class="`col-sm-${value[key].col}`">
                <component
                    :is="`${detail.component}`"
                    :data="data"
                >
                </component>
            </div>
        </div>
    </Layout>
</template>
